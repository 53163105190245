























































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../support/mixins'

import { generateImagePlaceholder } from '../../../shared/support/image-placeholder'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { SizeMap } from '../../../../dsl/composables'
import { Theme, Variant } from '../../../../dsl/atoms/Button'

import { ArticleCardLayout, ArticleCardProps } from './ArticleCard.contracts'
import {
  ARTICLE_CARD_COMPONENT_KEY,
  ARTICLE_CARD_DEFAULT_CONFIG, ArticleCardConfig
} from './ArticleCard.config'

/**
 * Component capable to render `ArticleCard` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ArticleCard>({
  name: 'ArticleCard',
  created (): void {
    this.config = this.getComponentConfig(
      ARTICLE_CARD_COMPONENT_KEY,
      ARTICLE_CARD_DEFAULT_CONFIG
    )
  }
})
export class ArticleCard extends Mixins(Vue, StructureConfigurable) {
  @Prop({ type: Array, required: false })
  public readonly categories?: ArticleCardProps['categories']

  @Prop({ type: String, required: true })
  public readonly content!: ArticleCardProps['content']

  @Prop({ type: Object, required: false })
  public readonly cover?: ArticleCardProps['cover']

  @Prop({ type: String, required: true })
  public readonly heading!: ArticleCardProps['heading']

  @Prop({ type: String, required: false, default: ArticleCardLayout.Vertical })
  public readonly layout?: ArticleCardProps['layout']

  @Prop({ type: String, required: true })
  public readonly link?: ArticleCardProps['link']

  @Prop({ type: String, required: true })
  public readonly publicationDate!: ArticleCardProps['publicationDate']

  /**
   * Determines button variant.
   */
  public get buttonVariant (): Variant {
    return this.getConfigProperty<Variant>('buttonVariant')
  }

  public get buttonTheme (): Theme {
    return this.getConfigProperty<Theme>('buttonTheme')
  }

  public get showDate (): boolean {
    return this.getConfigProperty<boolean>('showDate')
  }

  public get headingLevel (): number {
    return this.getConfigProperty<number>('headingLevel')
  }

  public get useSingleLink (): boolean {
    return this.getConfigProperty<boolean>('useSingleLink')
  }

  public get maxLinesConfig (): ArticleCardConfig['maxLines'] {
    return this.getConfigProperty<ArticleCardConfig['maxLines']>('maxLines')
  }

  /**
   * Determines whether categories are present.
   */
  public get hasCategories (): boolean {
    return typeof this.categories !== 'undefined' && this.categories.length > 0
  }

  /**
   * Determines whether article has `read-more` link.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' &&
      ((typeof this.link === 'string' && this.link.length > 0) || typeof this.link === 'object')
  }

  public get headingSize (): SizeMap {
    return this.isHorizontal ? SizeMap.Medium : SizeMap.XSmall
  }

  public get maxLines (): number {
    return this.isHorizontal ? this.maxLinesConfig.horizontal : this.maxLinesConfig.default
  }

  public get linkLabel (): string {
    if (this.isHorizontal) {
      return this.$t('front.content.organisms.ArticleCard.link.labelAlternative').toString()
    }

    return this.$t('front.content.organisms.ArticleCard.link.label').toString()
  }

  /**
   * Determines article image.
   */
  public get image (): ImageProps {
    if (!this.cover) {
      return generateImagePlaceholder('small')
    }

    return this.cover
  }

  public get isHorizontal (): boolean {
    return !!this.layout && this.layout === ArticleCardLayout.Horizontal
  }
}

export default ArticleCard
