


















import { Component, Mixins } from 'vue-property-decorator'

import { Inject } from '../../../support'
import { ISiteService, SiteServiceType } from '../../../contexts'
import { StructureConfigurable } from '../../../support/mixins'

import { AbstractModuleUi } from '../../abstract/ui'
import { ArticleCard, translateContentToArticleCard } from '../../../front/content/organisms/ArticleCard'

import { ArticlesTeaserModule } from '../ArticlesTeaser.contracts'
import {
  ARTICLES_TEASER_COMPONENT_KEY,
  ARTICLES_TEASER_DEFAULT_CONFIG
} from '../ArticlesTeaser.config'

/**
 * Container component for the `ArticlesTeaserModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ArticlesTeaserModuleUi>({
  name: 'ArticlesTeaserModuleUi',
  components: { ArticleCard },
  created (): void {
    this.config = this.getComponentConfig(
      ARTICLES_TEASER_COMPONENT_KEY,
      ARTICLES_TEASER_DEFAULT_CONFIG
    )
  }
})
export class ArticlesTeaserModuleUi extends Mixins<AbstractModuleUi<ArticlesTeaserModule>, StructureConfigurable>(
  AbstractModuleUi,
  StructureConfigurable
) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  /**
   * Articles teaser content
   */
  public get articles () {
    if (!this.content.articles?.length) {
      return
    }

    return this.content.articles.map(translateContentToArticleCard)
  }

  public get carouselConfig () {
    if (this.content.count) {
      return {
        ...this.config.carousel,
        responsive: {
          ...this.config.carousel.responsive,
          desktop: {
            ...this.config.carousel.responsive.desktop,
            perPage: this.content.count
          }
        }
      }
    }

    return this.config.carousel
  }

  public get hasCTA (): boolean {
    return this.getConfigProperty<boolean>('hasCTA')
  }

  public get ctaLink (): string {
    return this.siteService.getProperty('blogUrl') as unknown as string
  }

  /**
   * Articles teaser heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }
}

export default ArticlesTeaserModuleUi
